import { useScorecardsValidation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScorecardsValidation'
import React, { useEffect, useRef, useState } from 'react'
import {
  Action,
  Box,
  Button,
  Image,
  Popup,
  Relative,
  Scrollbar,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { useValidationStatusPopup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/hooks/useValidationStatusPopup'
import {
  PopupState,
  popupStateToElementsMap,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/constants'
import { EligibilityList } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/EligibilityList'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import { noop } from 'lodash'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  isOpen: boolean
  handleClose: VoidFunction
}

export const ValidateScorecardsPopup = ({
  cycle,
  eligibleGroups,
  isOpen,
  handleClose,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [popupState, setPopupState] = useState(PopupState.Default)
  const { progress, isPending, isCompleted, isFailed, isValid, validate } =
    useScorecardsValidation(cycle.id)
  const { toEditEligibleEmployees } = useNavigation()
  const { showValidationOngoing, showValidationCompleted, showValidationFailed } =
    useValidationStatusPopup()

  useEffect(() => {
    if (isCompleted) {
      showValidationCompleted()
      setPopupState(isValid ? PopupState.Valid : PopupState.Invalid)
    } else if (isFailed) {
      showValidationFailed()
    } else if (isPending) {
      showValidationOngoing(progress)
    }
  }, [isValid, isPending, isCompleted, progress])

  const { title, description, confirmLabel, cancelLabel, image } =
    popupStateToElementsMap[popupState]

  const handleConfirm = () => {
    if (popupState === PopupState.Valid) {
      // TODO: call "Generate Scorecards" handler in scope of https://revolut.atlassian.net/browse/REVC-7087"
      noop()
    } else {
      validate()
    }
  }

  return (
    <Popup size="sm" open={isOpen}>
      <VStack space="s-8" align="center">
        <Image size={88} image={image} />
        <Text variant="heading3">{title}</Text>
        <Text variant="caption">{description}</Text>
      </VStack>
      <Widget marginY="s-16">
        <Relative>
          <Box minHeight="s-8" maxHeight={300} borderRadius="r16" ref={scrollRef}>
            <EligibilityList
              state={popupState}
              cycle={cycle}
              eligibleGroups={eligibleGroups}
              scrollRef={scrollRef}
            />
            <Scrollbar scrollRef={scrollRef} cornerSpace="s-8" offset="s-2" />
          </Box>
        </Relative>
        {popupState === PopupState.Default && (
          <Box px="s-16" pt="s-8" pb="s-16">
            <Action useIcon="PlusCircle" onClick={() => toEditEligibleEmployees(cycle)()}>
              Add employee group
            </Action>
          </Box>
        )}
      </Widget>
      <Popup.Actions>
        <Button onClick={handleConfirm} pending={isPending}>
          {confirmLabel}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {cancelLabel}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
