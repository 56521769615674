import React from 'react'
import { Avatar, AvatarSize, HStack, Text } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import { useIsNewTable } from '../hooks'

interface CountryCellProps {
  country: IdAndName & {
    country_code?: string
  }
  size?: AvatarSize
}

export const CountryCell = ({ country, size }: CountryCellProps) => {
  const isNewTable = useIsNewTable()

  return (
    <HStack align="center" space="s-12">
      {!!country.country_code && (
        <Avatar
          size={isNewTable ? size : 24}
          variant="brand"
          image={getCountryFlagImageByCode(country.country_code)}
        />
      )}
      <Text>{country.name}</Text>
    </HStack>
  )
}
